@use './ncx-base.scss';

ncx-section {
    @extend ncx-base;
    padding: 5rem 3rem;

    .text {

        h1 {
            font-weight: 500;
            font-size: 2rem;
            margin-bottom: 3rem;
        }

        p {
            font-size: 1.5rem;

            &:not(:last-child) {
                margin-bottom: 3rem;
            }
        }
    }
}