@use './ncx-section.scss';
@import '../main.scss';

ncx-section-contacts {
    @extend ncx-section;
    background: #000046;
    /* fallback for old browsers */
    background: -webkit-linear-gradient(to right, #1CB5E0, #000046);
    /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(to right, #1CB5E0, #000046);
    /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
    padding: 3rem;

    p {
        font-size: 1.9rem;

        &:not(:last-child) {
            margin-bottom: 3rem;
        }
    }

    .form {
        max-width: 700px;
        margin: 0 auto;
        background-color: #fff;
        padding: 5rem 3rem;
        border-radius: 0.5rem;

        .success-message,
        .error-message {
            visibility: hidden;

            &.visible {
                visibility: visible;
            }
        }

        .success-message {
            color: $success-color;
        }

        .error-message {
            color: $error-color;
        }

        .contacts-list {
            font-size: 1.9rem;
            margin-bottom: 3rem;

            >div {
                display: flex;
                align-items: center;
                justify-content: center;

                &:not(:last-child) {
                    margin-bottom: 1rem;
                }

                i {
                    color: #888;
                    margin-right: 0.5rem;
                }
            }
        }

        label {
            font-size: 1.5rem;
        }
    }
}