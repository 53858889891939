@use './ncx-section.scss';

ncx-section-discover {
    @extend ncx-section;
    color: #333;

    line-height: 1.4;

    > div {
        margin-bottom: 3rem;
    }
    p {
        margin-bottom: 1.5rem;
    }
}