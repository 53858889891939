@import 'node_modules/materialize-css/sass/components/color-variables';
@import 'node_modules/materialize-css/sass/components/variables';
$primary-color: color("materialize-red", "lighten-2") !default;
$primary-color-light: lighten($primary-color, 15%) !default;
$primary-color-dark: darken($primary-color, 15%) !default;

$secondary-color: color("teal", "lighten-1") !default;
$success-color: color("green", "base") !default;
$error-color: color("red", "base") !default;
$link-color: color("light-blue", "darken-1") !default;
@import 'node_modules/materialize-css/sass/materialize.scss';

$input-focus-color: black !important;

html {
    font-size: 62.5%;
}

body {
    font-size: 1.5rem;
    font-family: "Raleway", sans-serif;
}