@use './ncx-section.scss';

ncx-section-hero {
    @extend ncx-section;
    height: 100vh;
    overflow: hidden;
    position: relative;
    color: #fff;
    padding: 0rem;
    background: #000046;
    /* fallback for old browsers */
    background: -webkit-linear-gradient(to right, #1CB5E0, #000046);
    /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(to right, #1CB5E0, #000046);
    /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */

    #particles-js {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }

    .hero-text {
        margin-top: 50vh;
        padding: 5rem 3rem;
        background-color: #1CB5E055;
        width: auto;

        h1 {
            font-size: 4rem;
            margin-bottom: 1rem;
            font-weight: 700;
        }

        p {
            font-size: 2.5rem;
            font-weight: 500;
        }
    }

}